export type Phase = 'p1' | 'p2' | 'p3' | 'p4' | 'p5' | 'p6';

const timelineData = {
  start: '2024-11-16T14:30:00.000Z',
  p1: '2024-11-16T15:30:20.000Z',
  p2: '2024-11-16T15:50:15.000Z',
  p3: '2024-11-16T16:43:49.000Z',
  p4: '2024-11-16T17:06:40.000Z',
  p5: '2024-11-16T17:24:00.000Z',
  p6: '2024-11-16T18:20:00.000Z',
  end: '2024-11-16T18:50:00.000Z',
};
const dictionaryShort = {
  p1: 'Tiangong',
  p2: 'Starlink',
  p3: 'ISS',
  p4: 'Tiangong',
  p5: 'Starlink',
  p6: 'ISS',
};

const dictionaryLong = {
  p1: 'Przelot Tiangong',
  p2: 'Przelot Starlink',
  p3: 'Przelot ISS',
  p4: 'Przelot Tiangong',
  p5: 'Przelot Starlink',
  p6: 'Przelot ISS',
};

const PHASES: Phase[] = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6'];

export {
  timelineData, dictionaryShort, dictionaryLong, PHASES,
};
